* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--primary-font-family);
}

body, html {
  height: 100%;    
  min-width: 100%;
  cursor: pointer;
  }

.container {
  width: 100%;
  max-width: 960px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

button {
  background-color: var(--secondary-color);
  color: #ffffff;
  height: 64px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: var(--basic-font-size);
  margin-top: 64px;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

button:hover{
  text-decoration: underline;
}

a {
  text-decoration: none;
  color: #000000;
}
