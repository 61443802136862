 :root{
  --primary-color: #F4ECD7; 
  --secondary-color: #84754E;
  --primary-font-family: 'Open Sans', sans-serif;
  --secondary-font-family: 'Proza Libre', sans-serif;
  --basic-font-size: 21px;
  --small-font-size: 16px;
  --big-font-size: 36px;
  --line-height: 34px;
  --primary-font: 'Proza Libre', Arial, Helvetica, sans-serif;
  --secondary-font: 'Open Sans', Arial, Helvetica, sans-serif;
  --small: 8px;
  --medium: 16px;
  --large: 48px;
}
