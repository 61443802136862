/* mobile menu */
.navigation-box{
  text-align: left;
  height: 100px;
}
.navigation-box img {
  height: 80px;
  margin-top: 15px;
  width: auto;
  padding: 10px;
}
.brand-name {
  text-align: center;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white; /* Dostosuj kolor tekstu nawigacji */
}
.hamburger {
  border: 0;
  height: 50px;
  width: 50px;
  padding: 1rem;
  border-radius: 50%;
  background-color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 30px;
  right: 5vw;
}
.navigation-menu.expanded ul {
  display: flex;
  justify-content: flex-end;
}

.navigation-menu ul {
  display: none;
  position: absolute;
  top: 6vh;
  right: 0;
  flex-direction: column;
  width: 100%;
  height: 260px;
  background-color: var(--primary-color);
  border: 1px solid #ffff;
  text-align: center;
}

.navigation-menu a {
  padding: 12px;
  font-size: 20px;
}
.navigation-menu a:hover {
  background-color: #ffffff;
  font-weight: 600;
}

/* HEADER */

.header-box:after {
  content: '';
  position: absolute;
  background-image: url('../src/images/forest.jpeg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 320px;
  z-index: -1;
  left: 0;
  right: 0;
}

.header-box {
  display: flex;
  color: white;
  padding: 30px 0;
}
.header {
  font-size: 2em; /* Dostosuj rozmiar czcionki według potrzeb */
  margin-top: 40px;
}
.lead {
  font-size: 1em; /* Dostosuj rozmiar czcionki według potrzeb */
}
.cta {
  background-color: var(--secondary-color);
  color: #ffffff;
  height: 64px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: var(--basic-font-size);
  margin-top: 64px;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  padding: 16px;
}

.cta:hover{
  text-decoration: underline;
}

/* ABOUT */
.about-box {
  margin-top: 81px;
  font-size: var(--basic-font-size);
  display: flex;
  font-size: var(--basic-font-size);
  line-height: 36px;
}

.about-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--basic-font-size);
  padding: var(--medium);
  line-height: 36px;
  margin-bottom: 36px;
}

.about-images img {
  max-height: 200vw;
  width: 100%;
  height: auto;
  padding: var(--medium);
}

.about-images div {
  position: relative;
  display: inline-block;
}

.about-images img {
  vertical-align: bottom;
}

.about-images div:after {
  content: '';
  position: absolute;
  left: 3rem;
  top: 3rem;
  background-color: var(--primary-color);
  width: 100%;
  height: 100%;
  z-index: -1;
}
footer {
  margin-top: 81px;
  margin-bottom: 81px;
}
.title {
  text-align: center;
} 
.title h2 {
  font-size: var(--big-font-size);
}
.title p {
  color: var(--secondary-color);
  font-size: var(--big-font-size);
}
.offer-boxes a {
  text-decoration: none;
  color: #000000;
}
.offer-boxes {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}
.offer-box {
  display: flex;
  width: 270px;
  height: 270px;
  margin: 16px;
  background-color: var(--primary-color);
  justify-content: center;
  align-items: center;
  font-size: var(--big-font-size);
  -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  border-radius: 3%
}
.offer-box:hover{
  color: #ffffff;
}

.face {
  background-image: url('../src/images/face.jpeg');
  background-size: cover;
  filter: sepia(70%);
}
.feet {
  background-image: url('../src/images/feet.jpeg');
  background-size: cover;
  filter: sepia(70%);
}
.hands {
  background-image: url('../src/images/nails.jpeg');
  background-size: cover;
  filter: sepia(70%);
}

.social {
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
  padding-bottom: 48px;
  align-items: center;
}
.social ul:after{
  content: '';
  position: absolute;
  background-color: var(--primary-color);
  width: 100%;
  height: 64px;
  z-index: -2;
  left: 0;
  right: 0;
}
.social ul {
  display: flex;
  align-items: center;
}
.social ul li {
  list-style: none;
  padding-left: 16px;
}
.facebook , .instagram{
  width: 24px;
}
.footer-box{
  font-family: var(--primary-font-family);
  padding: var(--medium);
  font-size: var(--basic-font-size);
  display: flex;
  justify-content: space-between;
  /*flex-wrap: wrap;*/
  line-height: 48px;
  align-items: center;
}
.footer-box-mobile{
  flex-direction: column;
  align-items: baseline;
  padding-bottom: 100px;

}

footer a {
  text-decoration: none;
  color: #000000;
}

.logoFooter img {
  max-width: 200px;
  margin: 32px;
  width: auto;
}
footer a:hover {
  text-decoration: underline;
}
.contact, .address {
  padding: 36px;
}

.button-term{
display: flex;
justify-content: center;
margin-bottom: 24px;
}

.button-term button{
  width: 300px;
}
.offer-title {
  text-align: center; 
}
.single-offer-box {
  line-height: 34px;
  letter-spacing: 1,5%;
}
.single-offer-box ul li {
  list-style-type: circle;
}
.single-offer-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  margin-top: 64px;
}
.single-offer-price-time{
  display: flex;
}
.single-offer-price-time p {
  padding: 8px
}
.single-offer-price-time p:nth-child(2) {
  border-left: black 1px solid;
}
.sinle-offer-details {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.single-offer-title:after {
  padding: 4px;
  content: '';
  background-color: rgb(247,243, 234, 0.25);
  position: absolute;
  height: 40px;
  z-index: -2;
  left: 0;
  right: 0;
}
.sinle-offer-details-mobile {
  flex-direction: column;
}
.sinle-offer-details-mobile div {
  padding-top: 24px;
}
/*.contraindications , .indications {
  width: 50%;
}*/
.single-offer-description {
  padding-bottom: 16px;
}
.contraindications ul , .indications ul {
  margin-left: 16px;
}
.offer-title  {
  background-color: var(--primary-color);
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.signature {
  font-family: 'Reenie Beanie', cursive;
  font-size: 48px;
  padding: 81px 0;
  text-align: right;
}

hr {
  border: 1px solid var(--primary-color);
}
.project {
  text-align: left;
  padding-bottom: 24px;
}
.project p {
 margin-top: 16px; 
}
.project-logos{
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding-bottom: 16px;
  margin-top: 16px; 
}
.project-logos img {
  margin: 8px;
}
.project-box {
  padding-top: 36px;
  margin-bottom: 36px;
}

.project-box p {
  padding: 10px  ;
}
